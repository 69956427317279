import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SankeyDiagram from "./components/patientFlowMimicSankeyDiagram.js";
import HeatMapDiagram from "./components/patientFlowMimicHeatMapDiagram.js";
export const _frontmatter = {
  "title": "Optimizing Patient Flow",
  "component": "none",
  "date": "2020-02-26T13:35:13.234Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Health care patient flow addresses the movement of patients, principally within a hospital, from point of admission to discharge. Optimizing patient flow enables more efficient designs and improves the allocation of resources involved in patient care`}<a parentName="p" {...{
        "href": "#icu"
      }}>{`[`}{`2`}{`]`}</a>{`. Be they medical attention/care, physical resources, or internal systems. This allows for enhanced coordination of care, improved patient safety and cost reductions.`}</p>
    <h3 {...{
      "id": "movement-of-patients",
      "style": {
        "position": "relative"
      }
    }}>{`Movement of patients`}<a parentName="h3" {...{
        "href": "#movement-of-patients",
        "aria-label": "movement of patients permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`The following data visualizations show the movement of patients in the Mimic III dataset. “MIMIC is an openly available dataset developed by the MIT Lab for Computational Physiology, comprising deidentified health data associated with ~60,000 intensive care unit admissions.”`}<a parentName="p" {...{
        "href": "#mimic"
      }}>{`[`}{`1`}{`]`}</a>{` The Sankey particle-flow diagram shows the historic flow of patients between wards.`}</p>
    <SankeyDiagram mdxType="SankeyDiagram" />
    <p>{`Patients are visualized as particles that travel along the paths between nodes. The paths between wards are sized proportionately with the number of transfers between those wards, and thus it is easy to determine which transfers occur most frequently. The heatmap shows the average new patient count by unit & hour. Representing ward occupancy at various times throughout the day`}<a parentName="p" {...{
        "href": "#team32"
      }}>{`[`}{`3`}{`]`}</a>{`.`}</p>
    <HeatMapDiagram mdxType="HeatMapDiagram" />
    <div className="gap-20"></div>
    <blockquote>
      <h3 parentName="blockquote" {...{
        "id": "references",
        "style": {
          "position": "relative"
        }
      }}>{`References`}<a parentName="h3" {...{
          "href": "#references",
          "aria-label": "references permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <ol parentName="blockquote">
        <li parentName="ol">
          <a name="mimic"></a>Johnson, Alistair EW, David J. Stone, Leo A. Celi, and Tom J. Pollard. "The MIMIC Code Repository: enabling reproducibility in critical care research." Journal of the American Medical Informatics Association (2017): ocx084.
        </li>
        <li parentName="ol">
          <a name="icu"></a>Bose S., et al. (2018) “Impact of Intensive Care Unit discharge delays on patient outcomes: A retrospective cohort study.” J. Intensive Care Med, 885066618800276, Oct. 2018. [Epub ahead of print]. (doi:10.1177/0885066618800276) (PMID:30270722)
        </li>
        <li parentName="ol">
          <a name="team32"></a>Tinatuh K., et al. (2019) "Data & Visual Analytics: Optimizing Patient Flow" Georgia Institute Of Technology.
        </li>
      </ol>
    </blockquote>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      